import { Dictionary } from '@/util/dictionary';
import { ValueRarityPair } from '@/util/random';
import { Rarity } from './itemInfos';
import { epicSkins, legendarySkins, skinInfos } from './skinInfos';

export enum SkinBannerType {
	None,
	VivaLaVida1,
}

export interface BannerInfo {
	name: string,
	pairs: Array<ValueRarityPair<number>>,
	raritySum: number,
	skinRarity: Dictionary<number>,
}
export const bannerInfos: Dictionary<BannerInfo> = {};

function setBannerInfo(bannerType: SkinBannerType, name: string) {
	let raritySum = 0;
	const pairs: Array<ValueRarityPair<number>> = [];
	const numSkins = skinInfos[bannerType].reduce((c, s) => c + (s ? 1 : 0), 0);
	const numEpics = epicSkins[bannerType]?.length || 0;
	const numLegendaries = legendarySkins[bannerType]?.length || 0;
	const skinRarity: Dictionary<number> = {};
	for (const skinInfo of skinInfos[bannerType]) {
		if (!skinInfo) {
			continue;
		}
		let rate = 0.12 / 100;
		if (skinInfo.rarity === Rarity.Epic) {
			rate = 5.1 / 100 / numEpics;
		} else if (skinInfo.rarity === Rarity.Rare) {
			rate = 1;
			rate -= 0.12 / 100 * numLegendaries;
			rate -= 5.1 / 100;
			rate /= numSkins - numLegendaries - numEpics;
		}
		skinRarity[skinInfo.skinId] = rate;
		raritySum += rate;
		pairs.push({ value: skinInfo.skinId, rarity: rate });
	}
	bannerInfos[bannerType] = {
		name,
		skinRarity,
		raritySum,
		pairs,
	};
}

setBannerInfo(SkinBannerType.VivaLaVida1, 'Viva La Vida');
