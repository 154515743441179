






















































































import { StabfishAccountInventory } from '@/game/infos/firestoreFiles';
import { FishType } from '@/game/infos/fishInfos';
import { Rarity } from '@/game/infos/itemInfos';
import {
  decodeSkinId,
  epicSkins,
  legendarySkins,
  SkinInfo,
  skinInfos,
} from '@/game/infos/skinInfos';
import { bannerInfos, SkinBannerType } from '@/game/infos/skinBannerInfos';
import { Global } from '@/store/globalz';
import userx from '@/store/modules/userx';
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { Dictionary } from 'vue-router/types/router';
import SkinButton from '../SkinButton.vue';
import { SkinIntelData } from '../SkinIntelTooltip.vue';
import globalx from '@/store/modules/globalx';
import settingx from '@/store/modules/settingx';

interface SkinData extends SkinInfo {
  rate: number;
  owned: number;
  equipped: boolean;
  isNew: boolean;
}

@Component({
  components: { SkinButton },
})
export default class BannerDetails1 extends Vue {
  public get inventory() {
    return userx.inventory;
  }

  public skins: Dictionary<SkinData[]> = { 4: [], 3: [], 2: [] };
  public rarityOwned: Dictionary<number> = { 4: 0, 3: 0, 2: 0 };
  public numSkins: Dictionary<number> = { 4: 0, 3: 0, 2: 0 };

  @Watch('inventory', { immediate: true })
  public inventoryChanged(inventory: StabfishAccountInventory) {
    const showNew = settingx.now.newSkin !== globalx.latestNewSkin;
    this.skins[Rarity.Legendary].length = 0;
    this.skins[Rarity.Epic].length = 0;
    this.skins[Rarity.Rare].length = 0;
    this.rarityOwned[Rarity.Legendary] = 0;
    this.rarityOwned[Rarity.Epic] = 0;
    this.rarityOwned[Rarity.Rare] = 0;
    for (const pair of bannerInfos[SkinBannerType.VivaLaVida1].pairs) {
      const { fishType, skinGroup } = decodeSkinId(pair.value);
      const info = skinInfos[skinGroup][fishType];
      const list = this.skins[info.rarity];
      const data: any = { ...info };
      data.rate = pair.rarity;
      data.owned = inventory.skins[info.skinId] || 0;
      if (data.owned) {
        this.rarityOwned[info.rarity]++;
      }
      data.equipped = inventory.equippedSkins[info.fishType] === 1;
      data.isNew =
        showNew &&
        globalx.newSkinGroup === skinGroup &&
        globalx.newSkins.includes(fishType);
      list.push(data);
    }
    this.numSkins[Rarity.Legendary] = this.skins[Rarity.Legendary].length;
    this.numSkins[Rarity.Epic] = this.skins[Rarity.Epic].length;
    this.numSkins[Rarity.Rare] = this.skins[Rarity.Rare].length;
  }

  public showSkinIntel(skin: SkinData) {
    const data: SkinIntelData = {
      targetId: 'banner1-' + skin.skinId,
      placement: 'top',
      container: 'banner-details',
      ...skin,
    };
    this.$root.$emit('showSkinIntel', data);
  }
  public hideSkinIntel() {
    this.$root.$emit('hideSkinIntel');
  }
}
