















































import { FishType } from '@/game/infos/fishInfos';
import { bannerInfos, SkinBannerType } from '@/game/infos/skinBannerInfos';
import { decodeSkinId, SkinInfo, skinInfos } from '@/game/infos/skinInfos';
import { Global } from '@/store/globalz';
import userx from '@/store/modules/userx';
import { Dictionary } from '@/util/dictionary';
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import SkinButton from './SkinButton.vue';
import { SkinIntelData } from './SkinIntelTooltip.vue';

interface SkinData extends SkinInfo {
  rate: number;
  isNew: boolean;
  equipped: boolean;
}
@Component({
  components: {
    SkinButton,
  },
})
export default class GachaResult extends Vue {
  public get skins() {
    const list: SkinData[] = [];
    if (!this.results) {
      return list;
    }
    const recentLen = this.results.length;
    const previousSkins: Dictionary<boolean> = {};
    for (let i = 0; i < recentLen; i++) {
      const skinId = this.results[i];

      const { skinGroup, fishType } = decodeSkinId(skinId);
      const info = skinInfos[skinGroup][fishType];
      const data: any = { ...info };
      data.rate = bannerInfos[this.bannerType].skinRarity[skinId];
      data.isNew = !this.skinSnapshot[skinId] && !previousSkins[skinId];
      data.equipped = userx.inventory.equippedSkins[info.fishType] === 1;
      previousSkins[skinId] = true;
      list.push(data);
    }
    return list;
  }
  @Prop({ default: 1 }) public bannerType!: SkinBannerType;
  @Prop({ default: () => [] }) public results!: number[];
  @Prop({ default: () => {} }) public skinSnapshot!: Dictionary<number>;
  public preventCloseTime = 0;
  public baseUrl = process.env.BASE_URL;

  @Watch('results')
  public onResultsChanged(value: number[]) {
    if (value) {
      this.preventCloseTime = Date.now() + 500 + value.length * 100;
    }
  }
  public onClose() {
    if (Date.now() > this.preventCloseTime) {
      this.$emit('close');
    }
  }

  public showSkinIntel(skin: SkinData, index: number) {
    const data: SkinIntelData = {
      targetId: 'banner-result-' + index,
      placement: 'top',
      container: 'gacha-result',
      ...skin,
    };
    this.$root.$emit('showSkinIntel', data);
  }
  public hideSkinIntel() {
    this.$root.$emit('hideSkinIntel');
  }
}
