






























































































































































import { preloadGachaSkins } from '@/client/controls/skinGachaControl';
import { loadGachaAssets } from '@/client/factory/gachaAssets';
import { BgmManager } from '@/client/sound/BgmManager';
import { levelRequirements } from '@/game/infos/levelInfos';
import { Global } from '@/store/globalz';
import assetx from '@/store/modules/assetx';
import globalx from '@/store/modules/globalx';
import userx from '@/store/modules/userx';
import { Tween } from '@/util/tweents';
import { wait } from '@/util/wait';
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import BannerDetails1 from '../gacha/banners/BannerDetails1.vue';
import SkinBanner from '../gacha/SkinBanner.vue';
import SkinButton from '../gacha/SkinButton.vue';
import BannerHistoryModal from '../gacha/BannerHistoryModal.vue';
import GachaResult from '../gacha/GachaResult.vue';
import { Dictionary } from '@/util/dictionary';
import { generateDateString } from '@/util/date';
import { ItemCode } from '@/game/infos/itemInfos';
import SkinPreviewer from '../gacha/SkinPreviewer.vue';
import CurrencyBar from '../gacha/CurrencyBar.vue';
import { SkinBannerType } from '@/game/infos/skinBannerInfos';
import SummonTips from '../gacha/SummonTips.vue';
import settingx from '@/store/modules/settingx';

@Component({
  components: {
    CurrencyBar,
    SkinPreviewer,
    GachaResult,
    SkinButton,
    SkinBanner,
    BannerDetails1,
    BannerHistoryModal,
    SummonTips,
  },
})
export default class SummonHome extends Vue {
  public get pageReady() {
    if (userx.loginStatus === 'loading') {
      return 'auth';
    } else if (userx.loginStatus === 'logged in') {
      if (!globalx.userDocsReady) {
        return 'loadingUser';
      }
      if (assetx.gachaAssetLoadState === 'completed') {
        return 'done';
      }
      return Math.round(assetx.gachaAssetLoadProgress);
    } else if (userx.loginStatus === 'logged out') {
      return 'redirect';
    }
  }
  public playAnimation = true;

  public baseUrl = process.env.BASE_URL;

  public results: number[] | null = null;
  public skinSnapshot: Dictionary<number> = {};
  public toFade = false;

  public created() {
    loadGachaAssets();
  }
  public mounted() {
    BgmManager.instance.play('bgmMainMenu');
    Tween.get(BgmManager.currentBgm, { override: true }).to(
      { volume: 1 },
      1000,
    );
  }
  @Watch('pageReady', { immediate: true })
  public pageReadyChanged(newVal: string) {
    if (newVal === 'auth') {
      this.$root.$emit('loading', 'Authenticating...');
    } else if (newVal === 'loadingUser') {
      this.$root.$emit('loading', 'Loading  User  Data...');
    } else if (newVal === 'done') {
      this.$root.$emit('loading', '');
      settingx.readNewSkin();
    } else {
      this.$root.$emit('loading', `Loading Gacha Assets...${newVal}%`);
    }
    if (newVal === 'redirect') {
      this.$router.push('/login');
    }
  }
  public async onGacha(isSingle: boolean) {
    if (Global.skinGachaControl) {
      if (document.activeElement instanceof HTMLElement) {
        document.activeElement.blur();
      }
      BgmManager.instance.play('');
      this.skinSnapshot = { ...userx.inventory.skins };
      this.toFade = true;
      try {
        // const [skins] = await Promise.all([
        //   gachaBanner1(
        //     numSummon,
        //     this.chargeBlackPearl ? (numSummon === 10 ? 190 : 200) : 0,
        //     this.autoConvertDuplicates,
        //   ),
        //   wait(500),
        // ]);
        const skins = await userx.gachaSkins({
          banner: SkinBannerType.VivaLaVida1,
          single: isSingle,
        });
        await preloadGachaSkins(skins);
        this.toFade = false;
        if (this.playAnimation) {
          globalx.updatePreventChallengeToast(true);
          await Global.skinGachaControl.playAnimation(skins);
          globalx.updatePreventChallengeToast(false);
        }
        this.results = skins;
        BgmManager.instance.play('bgmMainMenu');
      } catch (error) {
        this.showError((error as any).message);
        this.toFade = false;
        BgmManager.instance.play('bgmMainMenu');
      }
    }
  }

  public showBannerHistoryModal() {
    this.$bvModal.show('banner-history-modal');
  }

  public onShop() {
    Global.shopModal.show();
  }
  public onBack() {
    this.$router.go(-1);
  }

  public showError(error: any) {
    this.$root.$emit('error', error);
  }
}
