























































import StateButton from '@/components/inputs/StateButton.vue';
import { Rarity } from '@/game/infos/itemInfos';
import { ServerData } from '@/game/infos/serverInfos';
import { bannerInfos, SkinBannerType } from '@/game/infos/skinBannerInfos';
import { decodeSkinId, SkinInfo, skinInfos } from '@/game/infos/skinInfos';
import { ServerList } from '@/store/api/serverList';
import { ServerTime } from '@/store/api/serverTime';
import { Global } from '@/store/globalz';
import { EditState } from '@/store/models.def';
import globalx from '@/store/modules/globalx';
import userx from '@/store/modules/userx';
import { Dictionary } from '@/util/dictionary';
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import ServerComponent from './ServerComponent.vue';
import SkinButton from './SkinButton.vue';
import { SkinIntelData } from './SkinIntelTooltip.vue';

interface SkinData extends SkinInfo {
  rate: number;
  isNew: boolean;
  index: number;
}

@Component({
  components: {
    SkinButton,
  },
})
export default class BannerHistoryModal extends Vue {
  @Prop({ default: 1 }) public bannerType!: SkinBannerType;
  public get titleHtml() {
    return `Summon History`;
  }

  public get bannerInfo() {
    return bannerInfos[this.bannerType];
  }
  public get history() {
    return (
      userx.inventory.skinHistory[this.bannerType] || {
        news: [],
        recentDraws: [],
        numDraws: 0,
        numRarity: { 4: 0, 3: 0, 2: 0 },
      }
    );
  }
  public get rarityColor() {
    return {
      4: Global.rarityColors[Rarity.Legendary],
      3: Global.rarityColors[Rarity.Rare],
      2: Global.rarityColors[Rarity.Uncommon],
    };
  }

  public get skins() {
    const list: SkinData[] = [];
    const recentLen = this.history.recentDraws.length;
    for (let i = 0; i < recentLen; i++) {
      const skinId = this.history.recentDraws[i];
      const index = this.history.numDraws - i - 1;

      const { skinGroup, fishType } = decodeSkinId(skinId);
      const info = skinInfos[skinGroup][fishType];
      const data: any = { ...info };
      data.rate = bannerInfos[this.bannerType].skinRarity[skinId];
      data.isNew = this.history.news.includes(index);
      data.index = index;
      list.push(data);
    }
    return list;
  }

  public showSkinIntel(skin: SkinData, index: number) {
    const data: SkinIntelData = {
      targetId: 'banner-history-' + index,
      placement: 'top',
      container: 'banner-history-modal',
      ...skin,
    };
    this.$root.$emit('showSkinIntel', data);
  }
  public hideSkinIntel() {
    this.$root.$emit('hideSkinIntel');
  }
}
