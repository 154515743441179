













import GameBagCanvasComponent from '@/components/canvas/GameBagCanvasComponent.vue';
import { ItemCode, itemInfos } from '@/game/infos/itemInfos';
import { ItemCodeAmountPair } from '@/game/infos/unlockModels';
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import ResizeSensor from 'resize-sensor';
import { Global } from '@/store/globalz';

@Component({
  components: {},
})
export default class SkinBanner extends Vue {
  public width = 1350;
  public height = 700;
  public myStyle = { height: '700px' };
  public contentStyle = { transform: 'scale(1)' };
  public mounted() {
    if (!(this.$el as any).resizeSensor) {
      const res = new ResizeSensor(this.$el, this.onResize);
    }

    this.onResize();
  }
  public beforeDestroy() {
    if ((this.$el as any).resizeSensor) {
      ResizeSensor.detach(this.$el);
    }
  }
  public onResize() {
    this.width = this.$el.clientWidth;
    this.height = (this.width / 27) * 14;
    this.myStyle = { height: this.height + 'px' };
    this.contentStyle = { transform: 'scale(' + this.width / 1350 + ')' };
  }
}
